import { createI18n } from "vue-i18n";
import zh from "./zh";
import en from "./en";

const i18n = createI18n({
	legacy: false, // 如果要支持 compositionAPI，此项必须设置为 false
	locale: "en", // 设置语言类型
	globalInjection: true, // 全局注册$t方法
	silentTranslationWarn: true,
	fallbackWarn: false,
	missingWarn: false,
	messages: {
		zh,
		en
	}
});

export default i18n;
