export default {
	title: "标题",
	shortCode: "模板简码",
	description: "描述",
	updateTime: "更新时间",
	emailShortCode: "邮件简码",
	templateIllustrate: "邮件说明",
	enableStatus: "启用状态",
	emailTitle: "邮件标题",
	emailContent: "邮件内容",
	previewContent: "预览内容",
	orderCode: "订单编号",
	category: "分类",
	categoryManagement: "分类管理",
	articleTitle: "文章标题",
	releaseStatus: "发布状态",
	articleCategory: "文章分类",
	content: "内容",
	url: "Url",
	keywords: "关键词",
	articleContent: "文章内容",
	seoInfo: "SEO 信息",
	orderShipped: "订单发货",
	categoryName: "分类名称",
	status: "状态",
	sort: "排序",
	createTime: "创建时间",
	parentCategory: "父级分类",
	catrgorySort: "分类排序",
	topLevel: "顶级",
	downloadTemplate: "下载模板",
	import: "导入",
	state: "州",
	zipCode: "邮编",
	taxRegionName: "税区名称",
	estimatedCombinedRate: "预估综合费率",
	stateRate: "州费率",
	estimatedCountyRate: "预估国家费率",
	estimatedCityRate: "预估城市费率",
	estimatedSpecialRate: "预估地区费率",
	riskLevel: "风险等级",
	country: "国家",
	banner: "横幅",
	hotList: "热销列表",
	categoryEntry: "分类入口",
	productList: "产品列表",
	pcTerminal: "PC 端",
	mobileTerminal: "移动端",
	recommendedSize: "建议尺寸",
	enterJumpLink: "请输入图片跳转链接",
	chooseGood: "请选择商品",
	uploadImg: "上传图片",
	recommendSizeTips: "建议图片尺寸: 电脑端 {pc}, 移动端 {mobile}",
	orderDeliveryTime: "订单自动收货时间",
	orderDeliveryTimeTips: "订单自动确认收货，距离商品发货后天数",
	dropFile: "将文件拖放到此处或",
	clickUpload: "点击上传",
	rules: {
		tip1: "请输入邮件简码",
		tip2: "请输入邮件说明",
		tip3: "请输入邮件标题",
		content: "内容为必填项",
		contentLimit: "内容长度不能超过 {limit} 个字符",
		seoUrl: "SEO url 为必填项.",
		parentCategory: "父级分类为必填项",
		categoryName: "分类名称为必填项",
		categorySort: "分类排序为必填项",
		length: "字符长度应改为 {min} 到 {max} 之间",
		sortCount: "排序不应超过3位数",
		requiredInterger: "必须为整数",
		pcImageUpload: "上传pc图",
		mobileImageUpload: "上传手机图",
		selectProduct: "请选择商品",
		urlRegexp: "请输入正确的url",
		category: "文章分类为必填项",
		title: "文章标题为必填项"
	}
};
