export default {
	modifyPassword: "Change password",
	username: "Username",
	email: "Email address",
	nameOfCompany: "Company name",
	role: "Role",
	companyNo: "Company Code ID",
	originPassword: "Original password",
	newPassword: "New password",
	confirmPassword: "Please comfirm your password",
	rules: {
		password: "Your password must be at least 8 characters and include at least one number and one letter",
		confirmPassword: "Two passwords you entered doesn't match, please try again"
	}
};
