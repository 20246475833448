export default {
	createGoods: "新建商品",
	editGoods: "编辑商品",
	goodsDetail: "商品详情",
	category: {
		goodsTransfer: "商品转移",
		name: "分类名称",
		parentCategory: "上级分类",
		sort: "分类排序",
		inputParentCategory: "请选择上级分类",
		inputCategoryName: "请输入分类名称",
		inputCategorySort: "请输入分类排序",
		enableTips: "你确定要启用这个商品分类吗",
		disableTips: "你确定要禁用这个商品分类吗",
		deleteTips: "你确定要删除这个商品分类吗 ?",

		transfer: "商品分类转移",
		toBeTransfer: "待转移分类",
		target: "目标分类",
		transferNum: "转移商品数量",
		tip1: "请选择目标分类"
	},
	standard: {
		name: "规格名称",
		desc: "规格描述",
		warning: "你确定要删除这些商品规格嘛",
		tip1: "请输入规格名称",
		tip2: "请输入规格描述"
	},

	goodsImg: "商品图片",
	goodsSku: "商品SKU",
	goodsErp: "内部编码(ERP)",
	goodsPrice: "价格(美元)",
	goodsStock: "库存",
	goodsTitle: "商品标题",
	goodsSpu: "SPU",
	goodsCategory: "商品分类",
	goodsType: "商品类型",
	physicalProduct: "线上实物",
	programProduct: "方案产品",
	// virtualProduct: "虚拟商品",
	sgipServices: "SGIP 服务",
	card4g: "4G卡产品",
	authorizationCodeProduct: "授权码产品",
	fourGRecharge: "4G充值",
	fourGCode: "4G授权码",
	putOn: "上架",
	getOff: "下架",
	batchPutOn: "批量上架",
	batchGetOff: "批量下架",
	batchDel: "批量删除",
	batchTransfer: "批量转换分类",

	batchRecover: "批量恢复",
	batchOperation: "批量操作",
	recover: "恢复",

	basicInfo: {
		title: "基础信息",
		weight: "重量",
		goodsSize: "商品尺寸",
		height: "高度",
		length: "长度",
		width: "宽度",
		isFreeShipping: "是否包邮",
		orderWarningNum: "订单预警值",
		goodsSwiper: "商品轮播图",
		buyOver: "购买数量超过",
		triggerWarning: "触发预警值",
		jumpLink: "跳转链接",
		tip1: "请输入商品类型",
		tip2: "请选择商品分类",
		tip3: "请输入商品标题",
		tip4: "请输入重量",
		tip5: "请输入商品尺寸",
		// tip6: "请输入订单预警值",
		tip7: "请输入SPU编码",
		tip8: "请输入跳转链接",
		tip9: "请上传商品图片"
	},
	skuManagement: {
		title: "SKU 管理",
		goodsStandard: "商品规格",
		addStandard: "添加规格",
		addTip: "*最多添加两类规格",
		thumbnail: "缩略图",
		orderNo: "商品序号",
		tip1: "请完善SKU管理信息",
		tip2: "规格属性有重复"
	},
	goodsDesc: {
		title: "商品介绍",
		detailDesc: "商品详情介绍",
		technicalStandard: "商品技术规格",
		packingList: "包装清单",
		compatibility: "兼容性",
		associatedDocuments: "关联文档",
		addDocuments: "添加文档",
		enterDocName: "请输入文档名称",
		tip1: "请填写商品详情介绍",
		tip2: "请完善商品介绍信息"
	},
	seoInfo: {
		title: "SEO 管理",
		tip1: "请输入 SEO URL"
	},

	saveAndPutOn: "保存并上架",

	batchOperate: {
		"1": "你确定要执行上架操作吗",
		"2": "你确定要执行下架操作吗",
		"3": "你确定要执行删除操作吗"
	},
	recoverGoodsTips: "你确定要恢复此商品嘛"
};
