export default {
	enable: "启用",
	disable: "禁用",
	add: "新增",
	warning: "警告",
	status: "状态",
	addTime: "添加时间",
	sort: "排序",
	operate: "操作",
	edit: "编辑",
	delete: "删除",
	batchDel: "批量删除",
	cancel: "取消",
	confirm: "确认",
	search: "搜索",
	reset: "重置",
	login: "登录",
	pleaseInput: "请输入",
	pleaseSelect: "请选择",
	release: "发布",
	cancelRelease: "取消发布",
	unrelease: "未发布",
	released: "已发布",
	unreleased: "未发布",
	operation: "操作",
	save: "保存",
	preview: "预览",
	create: "创建",
	import: "导入",
	detail: "详情",
	all: "全部",
	log: "日志",
	transfer: "转移",
	auditPassed: "审核通过",
	auditNotPassed: "审核不通过",
	refundAndCancel: "退款并取消",
	downloadOrder: "下载订单",
	completeDeal: "完成处理",
	orderShip: "订单发货",
	dollar: "美元",

	createTime: "创建时间",
	delTime: "删除时间",
	startTime: "开始时间",
	endTime: "结束时间",
	uploadImgTips: "请上传图片",
	check: "查看",
	acceptFormat: "支持格式",
	uploadFile: "上传文件",
	yes: "是",
	no: "否",
	unfold: "展开",
	fold: "收起",
	placeholderUsername: "请输入用户名",
	placeholderPassword: "请输入密码",
	download: "下载",

	tips: {
		warning: "温馨提示",
		modify: "你确定要修改这个状态吗？",
		delete: "你确定要删除这一行吗？",
		confirm: "确定",
		cancel: "取消",
		tip: "提示"
	},

	rules: {
		username: "请输入用户名",
		password: "请输入密码"
	},
	username: "用户名",
	password: "密码",
	logoutTip: "您是否确认退出登录？",
	logoutSuc: "退出登录成功！",

	fileFormatError: "文件格式错误！",
	fileSuc: "文件上传成功！",
	fileFailed: "文件上传失败，请您重新上传！",
	fileSizeLimitTip: "上传图片大小不能超过",
	fileNumLimitTip: "文件数超出限制，请移除后上传！",
	pageNotFound: "抱歉，您访问的页面不存在~",
	backToHome: "回到首页",
	timeoutTip: "请求超时！请您稍后重试"
	// menu: {
	// 	home: "首页",
	// 	goods: {
	// 		title: "商品管理",
	// 		list: "商品列表",
	// 		categoryManagement: "分类管理",
	// 		standardManagement: "规格管理"
	// 	}
	// }
};
