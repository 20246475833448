export default {
	title: "Title",
	shortCode: "Template Shortcode",
	description: "Description",
	updateTime: "UpdateTime",
	emailShortCode: "Email Shortcode",
	templateIllustrate: "Email Description",
	enableStatus: "Start-up Status",
	emailTitle: "Email Title",
	emailContent: "Email Content",
	previewContent: "Preview Content",
	orderCode: "Order No.",
	category: "Category",
	categoryManagement: "Category Management",
	articleTitle: "Article Title",
	releaseStatus: "Release Status",
	articleCategory: "Article Category",
	content: "Content",
	url: "Url",
	keywords: "Keywords",
	articleContent: "Article Content",
	seoInfo: "SEO Info",
	orderShipped: "Order Shipping",
	categoryName: "Category Name",
	status: "Status",
	sort: "Sort",
	createTime: "Creation Time",
	parentCategory: "Parent Category",
	catrgorySort: "Sort by Category",
	topLevel: "Top",
	downloadTemplate: "Download Template",
	import: "Import",
	state: "State",
	zipCode: "Zip Code",
	taxRegionName: "Tax Area Name",
	estimatedCombinedRate: "Estimated Composite Rates",
	stateRate: "State Rates",
	estimatedCountyRate: "Estimated National Rates",
	estimatedCityRate: "Estimated City Rates",
	estimatedSpecialRate: "Estimated Regional Rates",
	riskLevel: "Risk Level",
	country: "Country",
	banner: "Banner",
	hotList: "Hot List",
	categoryEntry: "Category Entrance",
	productList: "Product List",
	pcTerminal: "PC",
	mobileTerminal: "Mobile",
	recommendedSize: "Recommended Size",
	enterJumpLink: "Please input the image-redirecting link",
	chooseGood: "Please select product",
	uploadImg: "Upload images",
	recommendSizeTips: "Suggested image size: computer {pc}, mobile {mobile}",
	orderDeliveryTime: "Order delivery time",
	orderDeliveryTimeTips: "Orders received, days after shipment",
	dropFile: "Drag and drop the file here or",
	clickUpload: "Click to upload",
	rules: {
		tip1: "Please enter email shortcode",
		tip2: "Please enter email description",
		tip3: "Please enter email subject",
		content: "Content is required",
		contentLimit: "Content length cannot exceed {limit} characters",
		seoUrl: "SEO url is a required",
		parentCategory: "Parent category is required",
		categoryName: "Category name is required",
		categorySort: "Category sorting is required",
		length: "The character length should be between {min} and {max}",
		sortCount: "Sorting should not exceed 3 digits",
		requiredInterger: "Must be an integer",
		pcImageUpload: "Upload pc diagram",
		mobileImageUpload: "Upload mobile image",
		selectProduct: "Please select product",
		urlRegexp: "Please enter correct url"
	}
};
