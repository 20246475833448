export default {
	createGoods: "Create Product",
	editGoods: "Edit Product",
	goodsDetail: "Product Detail",
	category: {
		goodsTransfer: "Product Transfer",
		name: "Category Name",
		parentCategory: "Top Category",
		sort: "Sort by Category",
		inputParentCategory: "Please select a parent category",
		inputCategoryName: "Please enter a category name",
		inputCategorySort: "Please enter category sorting",
		enableTips: "Enable this category?",
		disableTips: "Disable this category?",
		deleteTips: "Are you sure to delete this category?",
		transfer: "Category Transfer",
		toBeTransfer: "Category to be Transferred",
		target: "Target Category",
		transferNum: "Number of items to be transferred",
		tip1: "Please select the target category"
	},
	standard: {
		name: "Specification Name",
		desc: "Specification Description",
		warning: "Are you sure to delete these product specifications?",
		tip1: "Please enter the specification name",
		tip2: "Please enter the specification description"
	},
	goodsImg: "Product Image",
	goodsSku: "Product SKU",
	goodsErp: "Internal code",
	goodsPrice: "Price (USD)",
	goodsStock: "Stock",
	goodsTitle: "Product Title",
	goodsSpu: "SPU",
	goodsCategory: "Product Category",
	goodsType: "Product Type",
	physicalProduct: "Physical Products",
	programProduct: "Solution Products",
	sgipServices: "SGIP Service",
	virtualProduct: "Virtual Products",
	fourGRecharge: "4G Recharge",
	fourGCode: "4G License Code",
	putOn: "Publish",
	getOff: "Unpublish",
	batchPutOn: "Publish Selected",
	batchGetOff: "Unpublish Selected",
	batchDel: "Delete Selected",
	batchTransfer: "Assign to Categories",
	batchRecover: "Resume Selected",
	batchOperation: "Bulk Action",
	recover: "Resume",
	basicInfo: {
		title: "Basic Information",
		weight: "Weight",
		goodsSize: "Product Size",
		height: "Height",
		length: "Length",
		width: "Width",
		isFreeShipping: "Free Delivery?",
		orderWarningNum: "Order warning value",
		goodsSwiper: "Product rotation chart",
		buyOver: "Purchase quantity exceeds",
		triggerWarning: "Trigger warning value",
		jumpLink: "Redirecting",
		tip1: "Please enter product type",
		tip2: "Please select a product category",
		tip3: "Please enter the product title",
		tip4: "Please enter the weight",
		tip5: "Please enter the product size",
		tip7: "Please enter the SPU code",
		tip8: "Please enter the rederict link",
		tip9: "Please upload product image"
	},
	skuManagement: {
		title: "SKU Management",
		goodsStandard: "Product Specifications",
		addStandard: "Add Specifications",
		addTip: "*Add up to two specifications",
		thumbnail: "Thumbnails",
		orderNo: "Product Serial Number",
		tip1: "Please complete SKU management information",
		tip2: "Specification attributes are duplicated"
	},
	goodsDesc: {
		title: "Product Introduction",
		detailDesc: "Product Details",
		technicalStandard: "Technical Specifications",
		packingList: "Package List",
		compatibility: "Compatibility",
		associatedDocuments: "Related Documents",
		addDocuments: "Add Documents",
		enterDocName: "Please enter the document name",
		tip1: "Please fill in the product details",
		tip2: "Please complete the goods introduction"
	},
	seoInfo: {
		title: "SEO Management",
		tip1: "Please enter SEO URL"
	},
	saveAndPutOn: "Save and Publish",
	batchOperate: {
		"1": "Are you sure to publish?",
		"2": "Are you sure to unpublish?",
		"3": "Are you sure to delete?"
	},
	recoverGoodsTips: "Are you sure to resume?"
};
