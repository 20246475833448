export default {
	modifyPassword: "修改密码",
	username: "用户名称",
	email: "邮箱",
	nameOfCompany: "公司名称",
	role: "角色",
	companyNo: "公司编码",
	originPassword: "原始密码",
	newPassword: "新密码",
	confirmPassword: "确认密码",
	rules: {
		password: "至少8-16位字符，至少一个大写、一个小写、一个数字",
		confirmPassword: "两次密码输入不一致"
	}
};
