export default {
	id: "ID",
	email: "Email",
	name: "Name",
	firstName: "First name",
	lastName: "Last name",
	address: "Address",
	phone: "Phone",
	role: "Role",
	source: "Source",
	status: "Status",
	registrationTime: "Registration time",
	lastIp: "Most recent IP",
	lastSigninTime: "Most recent sign in time",
	batchEnable: "Enable selected",
	batchDisable: "Disable selected",
	installerInfo: "Installer's information",
	distributor: "Distributor's information",
	homeowner: "Homeowner",
	company: "Company",
	companyId: "Company ID",
	zipCode: "Zip Code",
	country: "Country",
	state: "State",
	city: "City"
};
