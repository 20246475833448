export default {
	entityGoods: "Physical Products",
	virtualGoods: "Virtual Products",
	allGoods: "All",
	orderCount: "Order Quantity",
	unpaid: "Unpaid",
	paid: "Paid",
	shipped: "Shipped",
	receivedGoods: "Received",
	refunding: "Refunding",
	returned: "Returned",
	canceled: "Canceled",
	creditcard: "Credit card",
	paypal: "Paypal",
	buyerInformation: "Buyer Information",
	ordersn: "Order Number",
	orderStatus: "Order Status",
	totalAmount: "Total Amount (USD)",
	payMethod: "Payment Methods",
	buyerName: "Buyer's Name",
	email: "Email",
	phone: "Telephone",
	createTime: "Creation Time",
	operationLog: "Operation Log",
	operationContent: "Operation content",
	operator: "Operator",
	time: "Time",
	reason: "Operating reason(s)",
	viewInvoice: "View invoice",
	orderType: "Order Type",
	auditFailedText: "If the review fails, the order will be cancelled and the buyer will be notified of the reason",
	auditNotpassed: "Review disapproved",
	auditPassed: "Review approved",
	refundAndCancelOrder: "Refund and cancel order",
	atLeastOneData: "Select at least one piece of data",
	dealOrderAmount: "Total number of orders processed",
	dealOrderAmountTips:
		"The order information has been downloaded, imported into the ERP system, and the sales order is completed",
	orderAmount: "Order amount",
	orderTotalAmount: "Total amount of orders",
	goodTotalAmount: "Total amount of goods",
	shippingFeeAmount: "Shipping fee",
	taxFeeAmount: "Tax",
	payInfo: "Payment Information",
	receiptNo: "Receipt No.",
	payTime: "Payment Time",
	tradeSerialNo: "Serial number",
	receiptSerialNo: "Receipt serial number",
	logisticsInformation: "Logistics Information",
	deliveryMethod: "Delivery Method",
	consigneeName: "Recipient name",
	normalDelivery: "Normal Delivery",
	courierCompany: "Delivery company",
	trackingNumber: "Tracking number",
	deliveryTime: "Delivery time",
	shippingAddress: "Shipping address",
	buyerInfo: "Buyer's information",
	installerInfo: "Installer's information",
	distributorInfo: "Distributor's information",
	company: "Company",
	zipCode: "zip code",
	country: "Country",
	state: "State",
	city: "City",
	address: "Address",
	goodInfo: "Product information",
	goodName: "Product name",
	googImage: "Product image",
	goodCode: "Product Code",
	specification: "Specification",
	quantity: "Purchase quantity",
	price: "Unit price",
	remark: "Remark",
	waitAudit: "Pending review",
	audited: "Reviewed",
	processing: "To be processed",
	processed: "Processed",
	batchDownload: "Download selected",
	batchCompleteProcessing: "Selected complete processing",
	waitFinancialReview: "Pending Financial Review",
	waitApply: "Application pending",
	applied: "Applied For",
	passed: "Passed",
	applyFailed: "Application Failed",
	sgipCode: "SGIP Number",
	applyStatus: "Apply Status",
	homeowner: "Homeowner",
	developerContact: "Developer Contact",
	applyDoc: "Required Documents",
	installerContact: "Installer Contact",
	contactName: "Contact Name",
	mailingAddress: "Mailing Address",
	mailingAddress2: "Mailing Address2",
	phoneNumber: "Phone Number",
	companyName: "Company Name",
	developerCompany: "Developer Company",
	developerKey: "Developer KEY",
	SGIPAccount: "SGIP Account",
	SGIPPassword: "SGIP Password",
	applySubmitted: "Application submitted",
	applyApproved: "Application approved",
	applySubmittedTips:
		"Submission of application to SGIP has been completed, please fill in the application number provided by SGIP",
	confirmApplyFailed: "Confirm that the application failed?",
	confirmApplyPassed: "Confirm that the application is passed?",

	contractForInstallation: "Contract for Installation",
	electricalUtilityBill: "Electrical Utility Bill",
	gasUtilityBill: "Gas Utility Bill",
	approvedPlanset: "Approved Planset",
	billOfMaterialsFromDistributor: "Bill of materials from distributor",
	interconnectionPTOLetterFromUtility: "Interconnection PTO Letter from utility",

	placeholder: {
		buyerInformation: "Please enter your name, phone or email",
		auditFailedReason: "Please enter the reason for the review failure, required",
		auditSuccessReason: "Please enter the reason for review, optional",
		refundReason: "Please enter the reason for refund, required",
		sgipCode: "Application Number from SGIP",
		inputSgipCode: "Please enter the SGIP code"
	},
	rules: {
		reason: "Reason Required",
		sgipRequired: "Application Number is Required."
	}
};
