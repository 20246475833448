//维护一份权限路由，从后端取到menu-list后，和routerMap比较
//! Setting 图标会导致页面卡顿，谨慎使用
export const routerMap = [
	{
		path: "/home/index",
		name: "home",
		component: "/home/index",
		meta: {
			icon: "HomeFilled",
			title: "首页",
			enTitle: "Home",
			isLink: "",
			isHide: false,
			isFull: false,
			isAffix: true,
			isKeepAlive: false
		}
	},
	{
		path: "/goods",
		name: "goods",
		redirect: "/goods/goods-list",
		meta: {
			icon: "Goods",
			title: "商品管理",
			enTitle: "",
			isLink: "",
			isHide: false,
			isFull: false,
			isAffix: false,
			isKeepAlive: false
		},
		children: [
			{
				path: "/goods/goods-list",
				name: "goods-list",
				component: "/goods/index",
				meta: {
					icon: "Goods",
					title: "商品列表",
					enTitle: "",
					isLink: "",
					isHide: false,
					isFull: false,
					isAffix: false,
					isKeepAlive: false
				}
			},
			{
				path: "/goods/create",
				name: "goods-create",
				component: "/goods/CreateGoods",
				meta: {
					icon: "Goods",
					title: "新增商品",
					enTitle: "",
					isLink: "",
					isHide: true,
					isFull: false,
					isAffix: false,
					isKeepAlive: false
				}
			},
			{
				path: "/goods/edit/:id",
				name: "goods-edit",
				component: "/goods/GoodsDetail",
				meta: {
					icon: "Goods",
					title: "编辑商品",
					enTitle: "",
					isLink: "",
					isHide: true,
					isFull: false,
					isAffix: false,
					isKeepAlive: false
				}
			},
			{
				path: "/goods/detail/:id",
				name: "goods-detail",
				component: "/goods/GoodsDetail",
				meta: {
					icon: "Goods",
					title: "商品详情",
					enTitle: "",
					isLink: "",
					isHide: true,
					isFull: false,
					isAffix: false,
					isKeepAlive: false
				}
			},
			{
				path: "/goods/category-management",
				name: "category-management",
				component: "/goods/CategoryManagement",
				meta: {
					icon: "Sort",
					title: "分类管理",
					enTitle: "",
					isLink: "",
					isHide: false,
					isFull: false,
					isAffix: false,
					isKeepAlive: false
				}
			},

			{
				path: "/goods/standard-management",
				name: "standard-management",
				component: "/goods/StandardManagement",
				meta: {
					icon: "Sell",
					title: "规格管理",
					enTitle: "",
					isLink: "",
					isHide: false,
					isFull: false,
					isAffix: false,
					isKeepAlive: false
				}
			},
			{
				path: "/goods/recover",
				name: "goods-recover",
				component: "/goods/GoodsRecover",
				meta: {
					icon: "Delete",
					title: "商品回收站",
					enTitle: "",
					isLink: "",
					isHide: false,
					isFull: false,
					isAffix: false,
					isKeepAlive: false
				}
			}
		]
	},
	{
		path: "/trade",
		name: "trade",
		redirect: "/trade/order-list",
		meta: {
			icon: "CreditCard",
			title: "交易管理",
			enTitle: "",
			isLink: "",
			isHide: false,
			isFull: false,
			isAffix: false,
			isKeepAlive: false
		},
		children: [
			{
				path: "/trade/order-list",
				name: "trade-order-list",
				component: "/trade/order/index",
				meta: {
					icon: "CreditCard",
					title: "订单列表",
					enTitle: "",
					isLink: "",
					isHide: false,
					isFull: false,
					isAffix: false,
					isKeepAlive: false
				}
			},
			{
				path: "/trade/order-details",
				name: "trade-order-details",
				component: "/trade/order/details",
				meta: {
					icon: "CreditCard",
					title: "订单详情",
					enTitle: "",
					isLink: "",
					isHide: true,
					isFull: false,
					isAffix: false,
					isKeepAlive: false
				}
			},
			{
				path: "/trade/compliance-audit",
				name: "trade-compliance-audit",
				component: "/trade/order/index",
				meta: {
					icon: "CreditCard",
					title: "合规审核",
					enTitle: "",
					isLink: "",
					isHide: false,
					isFull: false,
					isAffix: false,
					isKeepAlive: false
				}
			},

			{
				path: "/trade/financial-audit",
				name: "trade-financial-audit",
				component: "/trade/order/index",
				meta: {
					icon: "CreditCard",
					title: "财务审核",
					enTitle: "",
					isLink: "",
					isHide: false,
					isFull: false,
					isAffix: false,
					isKeepAlive: false
				}
			},
			{
				path: "/trade/sgip-apply",
				name: "sgip-apply",
				component: "/trade/order/index",
				meta: {
					icon: "CreditCard",
					title: "申请列表",
					enTitle: "",
					isLink: "",
					isHide: false,
					isFull: false,
					isAffix: false,
					isKeepAlive: true
				}
			},
			{
				path: "/trade/refund-order",
				name: "trade-refund-order",
				component: "/trade/order/index",
				meta: {
					icon: "CreditCard",
					title: "退款订单",
					enTitle: "",
					isLink: "",
					isHide: false,
					isFull: false,
					isAffix: false,
					isKeepAlive: false
				}
			},
			{
				path: "/trade/erp-order",
				name: "trade-erp-order",
				component: "/trade/order/index",
				meta: {
					icon: "CreditCard",
					title: "ERP订单",
					enTitle: "",
					isLink: "",
					isHide: false,
					isFull: false,
					isAffix: false,
					isKeepAlive: false
				}
			},
			{
				path: "/trade/warehouse-order",
				name: "trade-warehouse-order",
				component: "/trade/order/index",
				meta: {
					icon: "CreditCard",
					title: "仓库订单",
					enTitle: "",
					isLink: "",
					isHide: false,
					isFull: false,
					isAffix: false,
					isKeepAlive: false
				}
			}
		]
	},

	{
		path: "/member",
		name: "member-management",
		redirect: "/member/member-list",
		meta: {
			icon: "Avatar",
			title: "会员管理",
			enTitle: "Member Management",
			isLink: "",
			isHide: false,
			isFull: false,
			isAffix: false,
			isKeepAlive: false
		},
		children: [
			{
				path: "/member/member-list",
				name: "member-list",
				component: "/member/index",
				meta: {
					icon: "Avatar",
					title: "会员列表",
					enTitle: "Member List",
					isLink: "",
					isHide: false,
					isFull: false,
					isAffix: false,
					isKeepAlive: false
				}
			}
		]
	},
	{
		path: "/content",
		name: "content",
		redirect: "/content/email-template",
		meta: {
			icon: "Notebook",
			title: "内容管理",
			enTitle: "Content Management",
			isLink: "",
			isHide: false,
			isFull: false,
			isAffix: false,
			isKeepAlive: false
		},
		children: [
			{
				path: "/content/home",
				name: "home-managament",
				component: "/content/home/index",
				meta: {
					icon: "Monitor",
					title: "首页管理",
					enTitle: "Home Management",
					isLink: "",
					isHide: false,
					isFull: false,
					isAffix: false,
					isKeepAlive: false
				}
			},
			{
				path: "/content/email-template",
				name: "email-template",
				component: "/content/email/index",
				meta: {
					icon: "Message",
					title: "邮件模板",
					enTitle: "Email Template",
					isLink: "",
					isHide: false,
					isFull: false,
					isAffix: false,
					isKeepAlive: false
				}
			},
			{
				path: "/content/edit-email-template",
				name: "edit-email-template",
				component: "/content/email/editEmailTemplate/index",
				meta: {
					icon: "Edit",
					title: "编辑模板",
					enTitle: "Edit Email Template",
					isLink: "",
					isHide: true,
					isFull: false,
					isAffix: false,
					isKeepAlive: false
				}
			},
			{
				path: "/content/add-email-template",
				name: "add-email-template",
				component: "/content/email/editEmailTemplate/index",
				meta: {
					icon: "Edit",
					title: "新增模板",
					enTitle: "Edit Email Template",
					isLink: "",
					isHide: true,
					isFull: false,
					isAffix: false,
					isKeepAlive: false
				}
			},
			{
				path: "/content/article",
				name: "content-article-management",
				component: "/content/article/index",
				meta: {
					icon: "Document",
					title: "文章管理",
					enTitle: "Article Management",
					isLink: "",
					isHide: false,
					isFull: false,
					isAffix: false,
					isKeepAlive: false
				}
			},
			{
				path: "/content/add-article",
				name: "content-add-article",
				component: "/content/article/EditArticle/index",
				meta: {
					icon: "Edit",
					title: "新增文章",
					enTitle: "Add Article",
					isLink: "",
					isHide: true,
					isFull: false,
					isAffix: false,
					isKeepAlive: false
				}
			},
			{
				path: "/content/edit-article",
				name: "content-edit-article",
				component: "/content/article/EditArticle/index",
				meta: {
					icon: "Edit",
					title: "文章修改",
					enTitle: "Edit Article",
					isLink: "",
					isHide: true,
					isFull: false,
					isAffix: false,
					isKeepAlive: false
				}
			},
			{
				path: "/content/article-category",
				name: "content-article-category",
				component: "/content/article/CategoryManagement/index",
				meta: {
					icon: "Files",
					title: "分类管理",
					enTitle: "Category Management",
					isLink: "",
					isHide: true,
					isFull: false,
					isAffix: false,
					isKeepAlive: false
				}
			},
			{
				path: "/content/tax-template",
				name: "content-tax-template",
				component: "/content/tax/index",
				meta: {
					icon: "PieChart",
					title: "税费模板",
					enTitle: "Tax Template",
					isLink: "",
					isHide: false,
					isFull: false,
					isAffix: false,
					isKeepAlive: false
				}
			},
			{
				path: "/content/base-config",
				name: "content-base-config",
				component: "/content/base/index",
				meta: {
					icon: "Management",
					title: "基础配置",
					enTitle: "Base Config",
					isLink: "",
					isHide: false,
					isFull: false,
					isAffix: false,
					isKeepAlive: false
				}
			}
		]
	},
	{
		path: "/user",
		name: "user-setting",
		redirect: "/user/menu-list",
		meta: {
			icon: "Tools",
			title: "系统设置",
			enTitle: "",
			isLink: "",
			isHide: false,
			isFull: false,
			isAffix: false,
			isKeepAlive: false
		},
		children: [
			{
				path: "/user/menu-list",
				name: "menu-list",
				component: "/user/menu/index",
				meta: {
					icon: "Menu",
					title: "菜单管理",
					enTitle: "",
					isLink: "",
					isHide: false,
					isFull: false,
					isAffix: false,
					isKeepAlive: false
				}
			},
			{
				path: "/user/power-list",
				name: "power-list",
				component: "/user/power/index",
				meta: {
					icon: "WindPower",
					title: "权限管理",
					enTitle: "",
					isLink: "",
					isHide: false,
					isFull: false,
					isAffix: false,
					isKeepAlive: false
				}
			},
			{
				path: "/user/role-list",
				name: "role-list",
				component: "/user/role/index",
				meta: {
					icon: "UserFilled",
					title: "角色管理",
					enTitle: "",
					isLink: "",
					isHide: false,
					isFull: false,
					isAffix: false,
					isKeepAlive: false
				}
			},
			{
				path: "/user/admin-list",
				name: "admin-list",
				component: "/user/admin/index",
				meta: {
					icon: "Avatar",
					title: "账号管理",
					enTitle: "",
					isLink: "",
					isHide: false,
					isFull: false,
					isAffix: false,
					isKeepAlive: false
				}
			}
		]
	},
	{
		path: "/user-center",
		name: "user-center",
		component: "/user-center/index",
		meta: {
			icon: "",
			title: "个人中心",
			enTitle: "",
			isLink: "",
			isHide: true,
			isFull: false,
			isAffix: false,
			isKeepAlive: false
		}
	}
];
