import user from "./user";
import goods from "./goods";
import common from "./common";
import content from "./content";
import member from "./member";
import transaction from "./transaction";
import personal from "./personal";

export default {
	home: {
		welcome: "欢迎使用"
	},
	tabs: {
		more: "更多",
		refresh: "刷新",
		maximize: "最大化",
		closeCurrent: "关闭当前",
		closeOther: "关闭其它",
		closeAll: "关闭所有"
	},
	header: {
		componentSize: "组件大小",
		language: "国际化",
		theme: "全局主题",
		layoutConfig: "布局设置",
		primary: "primary",
		darkMode: "暗黑模式",
		greyMode: "灰色模式",
		weakMode: "色弱模式",
		fullScreen: "全屏",
		exitFullScreen: "退出全屏",
		personalData: "个人信息",
		personalCenter: "个人中心",
		changePassword: "修改密码",
		logout: "退出登录"
	},
	common,
	user,
	goods,
	content,
	member,
	transaction,
	personal
};
