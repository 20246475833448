export default {
	add: "Add",
	modify: "Change",
	username: "Username",
	password: "Password",
	confimrPassword: "Confirm password",
	name: "Name",
	status: "Status",
	role: "Role",
	remark: "Remark",
	lastIp: "Most recent login ip",
	loginCount: "Login times",
	lastLoginTime: "Most recent login time",
	operate: "Operate",
	menuName: "Name of menu",
	menuAddress: "Address of menu",
	menuType: "Type of menu",
	parent: "Parent",
	updateTime: "Update time",
	chineseName: "Chinese name",
	englishName: "English name",
	path: "Route",
	icon: "Icon",
	sort: "Sort by",
	standalone: "Whether to display independently",
	permissionCodeOrPath: "Permission code/Address",
	permissionCodeName: "Permission code/Address name",
	yes: "Yes",
	no: "No",
	showType: "Display types",
	public: "Public",
	login: "Login",
	permission: "Permission",
	accessType: "Type of access",
	addBy: "Add by",
	addTime: "Add time",
	edit: "Edit",
	delete: "Delete",
	modifyPassword: "Change password",
	enable: "Enable",
	disabled: "Disable",
	universalAccess: "General access",
	loginAccess: "Login access",
	permissionAccess: "Permission access",
	permissionCode: "Permission code",
	introduce: "Introduce",
	ip: "IP",
	createUser: "Create user",
	createAt: "Creation time",
	updateAt: "Update time",
	rules: {
		username: "User name is required ",
		password: "Password is required",
		passwordRegExp: "At least 8-16 characters, 1 uppercase letter, 1 lowercase letter and 1 number",
		name: "Name is required",
		status: "Status is required",
		mustNumber: "Sort by is required",
		isStandalone: "Independent display or not is required",
		title: "Chinese name is required",
		enTitle: "English name is required",
		path: "Route is required",
		parent: "Parent is required",
		icon: "Icon is required",
		sort: "Sort by is required",
		standalone: "Independent display or not is required",
		permissionCodeOrPath: "Permission code or route is required",
		accessType: "Access type is required",
		introduce: "Introduction is required",
		permissionCode: "Permission code is required",
		ip: "IP is required"
	},
	createSuccess: "Created successfully",
	modifySuccess: "Modified successfully",
	refreshedAfter: "Page will redirect after 2 seconds",
	message: {
		modifySuccess: "Modified successfully"
	},
	warning: "Warning",
	tips: {
		deleteMenu: "This action will permanently delete the menu, want to continue?",
		deletePermissionCode: "This action will permanently delete the permission/address, want to continue?",
		deleteRole: "This action will permanently delete the permission code/address, want to continue?",
		deleteIp: "This action will permanently delete the IP, want to continue?"
	}
};
