export default {
	entityGoods: "实物商品",
	virtualGoods: "虚拟商品",
	allGoods: "所有",
	orderCount: "订单数量",

	unpaid: "未付款",
	paid: "已付款",
	shipped: "已发货",
	receivedGoods: "已收货",
	refunding: "退款中",
	returned: "已退货",
	canceled: "已取消",
	waitShip: "待发货",
	shipping: "发货中",

	creditcard: "信用卡",
	paypal: "Paypal",
	buyerInformation: "买家信息",
	ordersn: "订单编号",
	orderStatus: "订单状态",
	totalAmount: "总金额(美元)",

	payMethod: "支付方式",
	buyerName: "买家姓名",
	email: "邮箱",
	phone: "电话",
	createTime: "创建时间",
	operationLog: "操作日志",
	operationContent: "操作内容",
	operator: "操作人",
	time: "时间",
	reason: "操作原因",
	viewInvoice: "查看发票",
	orderType: "订单类型",
	auditFailedText: "审核不通过，订单将被取消并通知买家原因",
	auditNotpassed: "审核不通过",
	auditPassed: "审核通过",
	refundAndCancelOrder: "退款并取消订单",
	atLeastOneData: "至少选择一条数据",
	dealOrderAmount: "共处理订单数量",
	dealOrderAmountTips: "已完成下载订单信息，导入到ERP系统，完成销货单制单",
	orderAmount: "订单金额",
	orderTotalAmount: "订单总金额",
	goodTotalAmount: "商品总金额",
	shippingFeeAmount: "运费金额",
	taxFeeAmount: "税费",
	payInfo: "支付信息",
	receiptNo: "收款单号",
	payTime: "支付时间",
	tradeSerialNo: "交易流水号",
	logisticsInformation: "物流信息",
	deliveryMethod: "配送方式",
	consigneeName: "收货人姓名",
	normalDelivery: "普通配送",
	courierCompany: "快递公司",
	trackingNumber: "快递单号",
	deliveryTime: "发货时间",
	shippingAddress: "收货地址",
	buyerInfo: "买家信息",
	installerInfo: "安装商信息",
	distributorInfo: "分销商信息",
	company: "公司",
	zipCode: "邮编",
	country: "国家",
	state: "州",
	city: "城市",
	address: "详细地址",
	goodInfo: "商品信息",
	goodName: "商品名称",
	googImage: "商品图片",
	goodCode: "商品编码",
	specification: "规格",
	quantity: "购买数量",
	price: "单价",
	remark: "备注",
	waitAudit: "待审核",
	audited: "已审核",
	processing: "待处理",
	processed: "已处理",
	batchDownload: "批量下载",
	batchCompleteProcessing: "批量完成处理",
	waitFinancialReview: "待财务审核",
	waitApply: "待申请",
	applied: "已申请",
	passed: "已通过",
	applyFailed: "申请失败",
	sgipCode: "SGIP 编号",
	applyStatus: "申请状态",
	homeowner: "房主",
	developerContact: "开发人员联系方式",
	applyDoc: "申请文档",
	installerContact: "安装商联系方式",
	contactName: "联系人姓名",
	mailingAddress: "邮寄地址",
	mailingAddress2: "邮寄地址2",
	phoneNumber: "电话号码",
	companyName: "安装商名称",
	developerCompany: "开发公司",
	developerKey: "开发人 KEY",
	SGIPAccount: "SGIP 账号",
	SGIPPassword: "SGIP 密码",
	applySubmitted: "申请提交",
	applyApproved: "申请通过",
	applySubmittedTips: "已完成向 SGIP 提交申请，请填写 SGIP 提供的申请编号",
	confirmApplyFailed: "确认申请失败?",
	confirmApplyPassed: "确认申请通过?",

	contractForInstallation: "安装合同",
	electricalUtilityBill: "电费帐单",
	gasUtilityBill: "燃气费帐单",
	approvedPlanset: "批准的计划",
	billOfMaterialsFromDistributor: "分销商提供的物料清单",
	interconnectionPTOLetterFromUtility: "公用事业公司发出的互连 PTO 函",

	placeholder: {
		buyerInformation: "请输入姓名，电话或邮箱",
		auditFailedReason: "请输入审核未通过原因，必填",
		auditSuccessReason: "请输入审核原因，非必填",
		refundReason: "请输入退款原因，必填",
		sgipCode: "SGIP 的申请编号",
		inputSgipCode: "请输入SGIP编码"
	},

	rules: {
		reason: "原因必填",
		sgipRequired: "请输入SGIP码"
	}
};
