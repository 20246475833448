export default {
	enable: "Enable",
	disable: "Disable",
	add: "Add",
	warning: "Warning",
	status: "Status",
	addTime: "Add time",
	sort: "Sort by",
	operate: "Operate",
	edit: "Edit",
	delete: "Delete",
	batchDel: "Delete selected",
	cancel: "Cancel",
	confirm: "Confirm",
	search: "Search",
	reset: "Reset",
	login: "Login",
	pleaseInput: "Please input",
	pleaseSelect: "Please select",
	release: "Release",
	cancelRelease: "Publish cancelled",
	unrelease: "Unpublished",
	released: "Published",
	unreleased: "Unpublished",
	operation: "Operation",
	save: "Save",
	preview: "Preview",
	create: "Create",
	import: "Import",
	detail: "Details",
	all: "All",
	log: "Logs",
	transfer: "Transfer",
	auditPassed: "Review approved",
	auditNotPassed: "Review unapproved",
	refundAndCancel: "Refund and cancellation",
	downloadOrder: "Download order",
	completeDeal: "Processing complete",
	orderShip: "Order delivery",
	dollar: "USD",
	createTime: "Create time",
	delTime: "Delete time",
	startTime: "Start time",
	endTime: "End time",
	uploadImgTips: "Upload picture",
	check: "Check",
	acceptFormat: "Supported formats",
	uploadFile: "Upload file",
	yes: "Yes",
	no: "No",
	unfold: "Open",
	fold: "Close",
	placeholderUsername: "Please enter your username.",
	placeholderPassword: "Please enter your password.",
	download: "Download",

	tips: {
		warning: "Tips",
		modify: "Are you sure to change this status?",
		delete: "Are you sure to delete this row?",
		confirm: "Confirm",
		cancel: "Cancel"
	},
	rules: {
		username: "Please enter your username.",
		password: "Please enter your password."
	},
	username: "User name",
	password: "Password",
	logoutTip: "Are you sure to log out?",
	logoutSuc: "Logout successfully.",
	fileFormatError: "File format error.",
	fileSuc: "File uploaded successfully.",
	fileFailed: "File upload failed, please re-upload.",
	fileSizeLimitTip: "The size of the uploaded image cannot exceed.",
	fileNumLimitTip: "The number of files exceeds the limit, please remove then upload the files.",
	pageNotFound: "Sorry, the page you visited does not exist.",
	backToHome: "Back to home page.",
	timeoutTip: "Request timed out! Please try again later"
};
