export default {
	add: "添加",
	modify: "修改",
	username: "用户名",
	password: "密码",
	confimrPassword: "确认密码",
	name: "名称",
	status: "状态",
	role: "角色",
	remark: "备注",
	lastIp: "最后登录ip",
	loginCount: "登录次数",
	lastLoginTime: "最后登录时间",
	operate: "操作",

	menuName: "菜单名称",
	menuAddress: "菜单地址",
	menuType: "菜单类型",
	parent: "父级",
	updateTime: "更新时间",

	chineseName: "中文名称",
	englishName: "英文名称",
	path: "路径",
	icon: "图标",
	sort: "排序",
	standalone: "是否独立展示",
	permissionCodeOrPath: "权限码/地址",
	permissionCodeName: "权限码/地址名称",
	yes: "是",
	no: "否",

	showType: "显示类型",
	public: "公开",
	login: "登录",
	permission: "权限",
	accessType: "访问类型",
	addBy: "添加人",
	addTime: "添加时间",

	edit: "编辑",
	delete: "删除",
	modifyPassword: "修改密码",
	enable: "启用",
	disabled: "禁用",
	universalAccess: "通用访问",
	loginAccess: "登录访问",
	permissionAccess: "权限访问",
	permissionCode: "权限码",

	introduce: "介绍",
	ip: "IP",
	createUser: "创建用户",
	createAt: "创建时间",
	updateAt: "更新时间",

	rules: {
		username: "用户名为必填项",
		password: "密码为必填项",
		passwordRegExp: "至少8-16个字符，至少1个大写字母，1个小写字母和1个数字",
		name: "名称为必填项",
		status: "状态为必填项",
		mustNumber: "排序为必填项",
		isStandalone: "是否独立展示为必填",
		title: "中文名称为必填项",
		enTitle: "英文名称为必填项",
		path: "路径为为必填项",
		parent: "父级为必填项",
		icon: "图标为必填项",
		sort: "排序为必填项",
		standalone: "是否独立展示为必填项",
		permissionCodeOrPath: "权限码或路径为必填项",
		accessType: "访问类型为必填项",
		introduce: "介绍为必填项",
		permissionCode: "权限码为必填项",
		ip: "IP为必填项"
	},

	createSuccess: "创建成功",
	modifySuccess: "修改成功",
	refreshedAfter: "页面将在 2s 后跳转",

	message: {
		modifySuccess: "修改成功"
	},

	warning: "Warning",
	tips: {
		deleteMenu: "此操作将永久删除菜单，要继续吗？",
		deletePermissionCode: "此操作将永久删除此权限/地址，是否要继续？",
		deleteRole: "此操作将永久删除此权限码/地址，是否要继续？",
		deleteIp: "此操作将永久删除该 ip，是否继续？"
	}
};
