import user from "./user";
import goods from "./goods";
import common from "./common";
import content from "./content";
import member from "./member";
import transaction from "./transaction";
import personal from "./personal";

export default {
	home: {
		welcome: "Welcome"
	},
	tabs: {
		more: "More",
		refresh: "Refresh",
		maximize: "Maximize",
		closeCurrent: "Close current",
		closeOther: "Close other",
		closeAll: "Close All"
	},
	header: {
		componentSize: "Component size",
		language: "Language",
		theme: "theme",
		layoutConfig: "Layout config",
		primary: "primary",
		darkMode: "Dark Mode",
		greyMode: "Grey mode",
		weakMode: "Weak mode",
		fullScreen: "Full Screen",
		exitFullScreen: "Exit Full Screen",
		personalData: "Personal Data",
		personalCenter: "Personal Center",
		changePassword: "Change Password",
		logout: "Logout"
	},
	common,
	user,
	goods,
	content,
	member,
	transaction,
	personal
};
