export default {
	id: "ID",
	email: "邮箱",
	name: "姓名",
	firstName: "名",
	lastName: "姓",
	address: "地址",
	phone: "电话",
	role: "角色",
	source: "来源",
	status: "状态",
	registrationTime: "注册时间",
	lastIp: "最后登录ip",
	lastSigninTime: "最后登录时间",
	batchEnable: "批量启用",
	batchDisable: "批量禁用",
	installerInfo: "安装商信息",
	distributor: "经销商信息",
	homeowner: "终端用户",
	company: "公司",
	companyId: "公司 ID",
	zipCode: "邮编",
	country: "国家",
	state: "州",
	city: "城市"
};
