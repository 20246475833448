/**
 * v-input-decimal
 * 输入框保留小数
 */
import type { Directive, DirectiveBinding } from "vue";
// /^0\.([1-9]|\d[1-9])$|^[1-9]\d{0,8}\.\d{0,2}$|^[1-9]\d{0,8}$/;
const Decimal: Directive = {
	mounted(el: HTMLElement, binding: DirectiveBinding) {
		if (typeof Number(binding.value) !== "number") {
			throw new Error("must be a number");
		}

		let RegStr = `^[\\+\\-]?\\d+\\.?\\d{0,${binding.value}}`;
		// let RegStr = new RegExp(`^\D*([1-9]\d*\.?\d{0,${binding.value}})$`);
		// let RegStr = new RegExp(`^(0|[1-9]\d*\.?\d{0,${binding.value}})$`);

		el.addEventListener("input", e => handleInput(e, binding.value, RegStr));
	},
	beforeUnmount(el: HTMLElement) {
		el.removeEventListener("input", e => handleInput(e, "", ""));
	}
};

const handleInput = (e: any, value: any, RegStr: RegExp | string) => {
	const matchVal = e.target.value.match(new RegExp(RegStr, "g"));
	console.log("matchVal", matchVal);
	const replaceVal = matchVal?.length ? matchVal[0] : matchVal;
	e.target.value = replaceVal;
};
export default Decimal;
